import { render, staticRenderFns } from "./TaduSvg.vue?vue&type=template&id=45bc1162&scoped=true&"
import script from "./TaduSvg.vue?vue&type=script&lang=js&"
export * from "./TaduSvg.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45bc1162",
  null
  
)

export default component.exports